import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';
import ButtonLink from 'components/atoms/ButtonLink';
import Delimiter from 'components/atoms/Delimiter';
import Form from './Form';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const Subtitle = styled.h3`
  margin-top: 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1em;
`;

const Col = styled.div`
  padding: 1em;
  width: 100%;
  flex: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 50%;
  }
`;

const StyledLink = styled(ButtonLink).attrs({
  neutral: true,
  inline: true,
})``;

const SignInPage = () => (
  <MainTemplate>
    <Helmet>
      <title>{title.signin}</title>
      <meta name="keywords" content={metaKeywords.signin} />
      <meta name="description" content={metaDescription.signin} />
    </Helmet>
    <Title>{heading.signin}</Title>
    <Row>
      <Col>
        <Form />
      </Col>
      <Col>
        <Subtitle>Новый пользователь</Subtitle>
        <p>У вас нет аккаунта?</p>
        <StyledLink to="/signup">Зарегистрироваться</StyledLink>
        <Delimiter />
        <Subtitle>Забыли пароль?</Subtitle>
        <StyledLink to="/password-reset">Восстановить пароль</StyledLink>
      </Col>
    </Row>
  </MainTemplate>
);

export default SignInPage;
