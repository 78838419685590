import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import Button from 'components/atoms/Button';
import Form from 'components/atoms/Form';
import Alert from 'components/atoms/Alert';
import signinMutation from 'graphql/mutations/signin.graphql';

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

@withRouter
export default class extends Component {
  state = {
    values: {
      email: '',
      password: '',
    },
    error: null,
    initialized: false,
  };

  componentDidMount() {
    this.setState({
      initialized: true,
    });
  }

  handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    this.setState((state) => ({
      values: {
        ...state.values,
        [name]: value,
      },
    }));
  };

  handleError = (error) => {
    let errorMessage = 'Произошла ошибка. Попробуйте позднее.';

    if (
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.code &&
      error.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
    ) {
      errorMessage = error.graphQLErrors[0].message;
    }
    this.setState({
      error: errorMessage,
    });
  };

  handleComplete = () => {
    this.props.history.push('/profile');
  };

  clearError = () => {
    this.setState({
      error: null,
    });
  };

  render() {
    return (
      <Mutation
        mutation={signinMutation}
        variables={this.state.values}
        onError={this.handleError}
        onCompleted={this.handleComplete}
      >
        {(signin, { loading }) => (
          <>
            {this.state.error && <StyledAlert type="error">{this.state.error}</StyledAlert>}
            <Form
              method="post"
              onSubmit={(event) => {
                event.preventDefault();
                this.clearError();
                signin();
              }}
            >
              <Form.Control>
                <Form.Label htmlFor="email">E-mail</Form.Label>
                <Form.Input
                  type="email"
                  id="email"
                  name="email"
                  value={this.state.values.email}
                  onChange={this.handleChange}
                  disabled={loading}
                  required
                />
              </Form.Control>
              <Form.Control>
                <Form.Label htmlFor="password">Пароль</Form.Label>
                <Form.Input
                  type="password"
                  id="password"
                  name="password"
                  value={this.state.values.password}
                  onChange={this.handleChange}
                  disabled={loading}
                  required
                />
              </Form.Control>
              <Button type="submit" primary disabled={loading || !this.state.initialized}>
                {loading || !this.state.initialized ? 'Загрузка...' : 'Войти'}
              </Button>
            </Form>
          </>
        )}
      </Mutation>
    );
  }
}
